@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&family=Playwrite+BE+VLG:wght@100..400&family=Playwrite+BE+WAL:wght@100..400&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');
/* Estilos generales de la aplicación */

  body {
    background-color: #fff4e1; 
  }
.App {
  text-align: center;
}
.img-fluid {
  transition: transform 0.3s ease-in-out;
}

.img-fluid:hover {
  transform: scale(1.1); /* Escala la imagen para que parezca que se acerca */
}

.img-lilo {
  transition: transform 0.3s ease-in-out;
}

.img-lilo:hover {
  transform: scale(1.05); /* Escala la imagen para que parezca que se acerca */
}

.team-member-img {
  width: 250px; /* Aumentamos el tamaño de la imagen */
  height: 250px; /* Aumentamos el tamaño de la imagen */
  object-fit: cover; /* Asegura que la imagen mantenga sus proporciones */
}

.team-container .col-md-3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Centra el contenido de las columnas */
  text-align: center; /* Centra el texto */
  min-height: 350px; /* Ajusta esta altura para que todas las columnas tengan el mismo tamaño */
}

.team-container p {
  margin: 0; /* Asegura que no haya márgenes adicionales en los párrafos */
}

/* Estilos para las cookies */

.cookie-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6); /* Fondo oscuro semi-transparente */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  z-index: 9999; /* Asegúrate de que esté por encima de otros elementos */
}

.cookiesContent {
  width: 90%;
  max-width: 500px; /* Tamaño máximo del banner */
  background-color: #8cc0ea;
  color: #000;
  text-align: center;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  position: relative;
}

.cookiesContent img {
  width: 80px;
  margin-bottom: 15px;
}

.cookiesContent p {
  margin-bottom: 20px;
  font-size: 16px;
}

button.close {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 20px;
  color: #c0c5cb;
  cursor: pointer;
}

button.accept,
button.decline {
  background-color: #11216c;
  border: none;
  border-radius: 5px;
  width: 200px;
  padding: 10px;
  font-size: 16px;
  color: white;
  cursor: pointer;
  margin: 5px;
}

button.decline {
  background-color: #ff6565; /* Color diferente para el botón de rechazo */
}

.img-lilo {
  width: 80%;
  margin-left: 50px;
}
/* Estilos para la politica de cookies */
/* CookiePolicy.css */

.cookie-policy {
  background-color: #f5f5f5;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.cookie-policy .container {
  max-width: 800px;
  margin: 0 auto;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.cookie-policy h1,
.cookie-policy h2 {
  color: #333;
}

.cookie-policy p,
.cookie-policy ul {
  color: #555;
}

.cookie-policy a {
  color: #007bff;
  text-decoration: none;
}

.cookie-policy a:hover {
  text-decoration: underline;
}

.cookie-policy ul {
  list-style-type: disc;
  margin-left: 20px;
}

/* Estilos para los botones y enlaces de navegación */
.custom-navbar {
  background-color: #FFE4E1;
  margin-left: 0px;
}
.custom-navbar-brand {
  padding: 10px 15px; /* Ajusta el padding según sea necesario */
  background-color: rgb(0, 38, 82);
  border-radius: 5px;
  color: white;
  font-family: "Roboto Condensed", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  margin-left: 10px;
}



.custom-navbar-brand:hover {
 background-color: #ffb6c1; /* Cambia el color del texto al pasar el ratón por encima */
 color: rgb(0, 38, 82)  !important;
}

.custom-nav-link {
  color:  rgb(0, 38, 82) !important; /* Color del texto de los enlaces */
  background-color: #ffb6c1 !important; /* Color de fondo de los botones */
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  margin: 0 10px;
  text-decoration: none;
}

.custom-nav-link:hover {
  background-color:  rgb(0, 38, 82) !important; /* Color de fondo al pasar el ratón */
  color: white !important;
}

.hero-container {
  position: relative;
  height: 60vh; /* Ajusta la altura al 60% de la ventana */
  background-color: rgb(0, 38, 82);
}
.logo {
width: 105.35%;
margin-left: -80px;

}


.overlay-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  background-color: rgba(188, 204, 225, 0.95); /* Fondo casi transparente (opacidad 0.3) */
  padding: 20px;
  border-radius: 10px;
  max-width: 700px; /* Limita el ancho del cuadro */
}

.overlay-content h1 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: rgb(0, 71, 102);
}

.overlay-content p {
  font-size: 1rem;
  margin-bottom: 20px;
  color: rgb(0, 71, 102);
}

.custom-btn {
  background-color: #08426e !important; /* Cambia este color al que desees */
  color: rgb(255, 255, 255) !important; /* Cambia el color del texto si es necesario */
  margin: 10px 5px;
}
.custom-btn:hover {
  background-color: #ffb6c1 !important; /* Cambia este color al que desees */
  color: rgb(0, 38, 82) !important; /* Cambia el color del texto si es necesario */
  margin: 10px 5px;
}



/* Estilos para los servicios */

.services-container {
  background-color: #BCCCE1; /* Color de fondo específico para este bloque */
  padding: 20px; /* Añadir relleno para un mejor espaciado */
  border-radius: 10px; /* Bordes redondeados */
  text-align: center; /* Centrando el texto y las imágenes */
}

.services-container h2 {
  color: rgb(0, 38, 82);
  padding-bottom: 30px;
}

.services-container .row {
  margin-bottom: 30px;
}

.services-container img {
  max-height: 300px;
  object-fit: cover;
  border-radius: 5px;
  width: 100%; /* Asegura que las imágenes ocupen todo el ancho de su contenedor */
}

.services-container .service-description {
  margin-top: 10px;
}

.services-container p {
  font-size: 1rem;
  color: #333;
  margin: 10px 0;
}

.services-container .btn {
  margin-top: 10px;
  font-size: 0.9rem;
  background-color: rgb(0, 38, 82);

}

.services-container .btn:hover {
  background-color: #ffb6c1;
}

 /*our team */
.team-container-images{
  margin-top: 30px;
}
.team-container-text{
  padding-bottom: 40px;
 }
/*footer*/
.footer {
  background-color: #ffE4e1;
  color: white;
  padding: 20px;
  margin-top: 70px;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

h4 {
 padding-bottom: 20px;
}
.contact-info,
.map-container {
  flex: 1;
  max-width: 50%;
  padding: 10px; /* Agrega un poco de espacio interno */
}

.contact-box,
.map-box {
  background-color: rgb(0, 38, 82);
  padding: 20px;
  border-radius: 8px;
}

.map-container {
  width: 100%; /* Ajusta el ancho del contenedor al 100% del espacio disponible */
  height: 300px; /* Establece una altura fija o ajusta según tus necesidades */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0px; /* Ajusta el margen superior según sea necesario */
  background-color: #fff4e1;
  
}

.map-container iframe {
  width: 100%; /* Aprovecha todo el ancho del contenedor */
  height: 120%; /* Aprovecha toda la altura del contenedor */
  border-radius: 8px; /* Borde redondeado opcional */
}

.footer-info {
  margin-top: 20px;
  text-align: center;
  color: rgb(0, 38, 82);
}

.social-container {
  margin-top: 20px;
}

.social-container a {
  margin-right: 10px;
  color: #B5D0CC;
}

.social-container a:hover {
  color: #ffb6c1;
}

/* Espacio adicional entre el cuadro de información y el mapa */
.contact-info {
  margin-right: 10px; /* Ajusta el margen derecho según sea necesario */
}
/* Espacio adicional entre el cuadro de información y el mapa */
.contact-info {
  margin-right: 10px; /* Ajusta el margen derecho según sea necesario */
}

.urgencias-container {
  background-color: rgb(0, 38, 82);
  margin-top: 70px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  color: white;
}

.horario-container, .telefono-urgencias {
  background-color: #BCCCE1;
  border-radius: 8px;
  color: rgb(0, 38, 82);
}

.h4 {
  display: flex;
  align-items: center;
}

.h4 .mr-2 {
  margin-right: 8px;
}

.text-center {
  text-align: center;
}

.mt-4 {
  margin-top: 1.5rem;
}

.p-3 {
  padding: 1rem;
}

/* contacto.js */

.contacto-container {
  background-color: rgb(0, 38, 82);
  padding: 20px;
  border-radius: 8px;
  box-shadow: white;
  margin-top: 60px;
  width: 50%; /* Ajusta el ancho del contenedor según sea necesario */
  margin-left: 26%; /* Ajusta el margen izquierdo según sea necesario */
  color: #fff; /* Color del texto dentro del contenedor */
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.input-box {
  margin-bottom: 20px; /* Espacio entre cada caja de entrada */
}

.input-box:last-child {
  margin-bottom: 30px; /* Más espacio antes del botón de envío */
}

.contact-form .form-label {
  font-weight: bold;
  margin-bottom: 5px; /* Espacio bajo las etiquetas */
  color: #fff; /* Color del texto de las etiquetas */
}

.contact-form .form-control {
  border-radius: 5px;
  padding: 10px;
  border: 1px solid #ccc; /* Borde de las cajas de entrada */
  background-color: #fff; /* Color de fondo de las cajas de entrada */
  color: #000; /* Color del texto dentro de las cajas de entrada */
}

.contact-form .mess {
  border-radius: 5px;
  padding: 10px;
  border: 1px solid #ccc; /* Borde del área de texto */
  background-color: #fff; /* Color de fondo del área de texto */
  color: #000; /* Color del texto dentro del área de texto */
  min-height: 100px; /* Altura mínima del área de texto */
}

.contact-form .submit-btn {
  background-color: #ffb6c1;
  border: none;
  border-radius: 5px;
  padding: 10px 20px; /* Ajuste de tamaño del botón */
  font-size: 14px; /* Ajuste de tamaño de la fuente del botón */
  width: 200px; /* Ajuste de ancho del botón */
  margin-left: 0; /* Ajuste del margen izquierdo del botón */
  align-self: center; /* Centrar el botón horizontalmente */
  color: #000; /* Color del texto del botón */
}

.text-center {
  text-align: center;
}

.my-4 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}


/* Peluqueria.js */

.social-icons {
  text-align: center; /* Centra los íconos horizontalmente */
  margin-top: 20px; /* Espacio superior respecto al título */
  margin-bottom: 20px; /* Espacio inferior respecto al formulario */
  padding-top: 10px; /* Espacio interior superior */
  padding-bottom: 10px; /* Espacio interior inferior */
  background-color: rgb(254, 175, 251);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.social-icons h4 {
  margin-bottom: 10px; /* Espacio inferior respecto al título */
  font-size: 1.2rem;
  color: rgb(0, 38, 82);
}

.social-icon {
  font-size: 2rem;
  color: rgb(0, 38, 82);
  transition: color 0.3s;
  margin: 0 5px; /* Espacio horizontal entre los íconos */
}

.social-icon:hover {
  color: rgb(255, 60, 180);
}

.peluqueria-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: rgb(0, 38, 82);
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 80px;
  
}

/* Estilos para el formulario de la peluquería */
.peluqueria-form {
  display: flex;
  flex-direction: column;
  margin-top: 20px; /* Espacio entre redes sociales y formulario */
}

/* Estilos para los campos del formulario */
.input-box-peluqueria {
  margin-bottom: 15px;
}

/* Estilos para las etiquetas de los campos */
.form-label-peluqueria {
  font-weight: bold;
  margin-bottom: 5px;
}

/* Estilos para los campos de entrada */
.form-control-peluqueria {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Estilos para el campo de mensaje */
.mess-peluqueria {
  height: 100px; /* Ajustar según sea necesario */
  resize: vertical; /* Permite redimensionar verticalmente */
}
.submit-btn{
  background-color: rgb(254, 175, 251);
  color: white !important;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  width: 30%;
  margin-left: 35%;
}

.submit-btn:hover {
  background-color: rgb(69, 212, 240);
}
/* Estilos para el botón de enviar */
.submit-btn-peluqueria {
  background-color: rgb(254, 175, 251);
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  width: 30%;
  margin-left: 35%;
}

.submit-btn-peluqueria:hover {
  background-color: rgb(69, 212, 240);
}
/* Estilos para la sección de mensaje de confirmación */
.message-container {
  text-align: center;
  margin-top: 20px;
  color: white;
}

/* Estilos generales */
.text-center {
  text-align: center;
}

.my-4 {
  margin-top: 4px;
  margin-bottom: 4px;
}

.intro {
  margin-bottom: 20px;
  color: white;
}

.intro h1 {
  font-size: 24px;
  margin-bottom: 10px;
  padding-left: 90px;
}

.intro p {
  font-size: 16px;
  line-height: 1.5;
}
.input-box-peluqueria {
  color: white;
}
@media (max-width: 768px) {

  .logo {
    opacity: 0%;
  }

  .hero-container {
    padding: 10px; /* Ajusta el padding para dispositivos móviles */
  }

  .overlay-content {
    width: 70%;
  }

  .overlay-content h1 {
    font-size: 20px; /* Ajusta el tamaño de fuente para que quepa mejor */
  }

  .overlay-content p {
    font-size: 14px; /* Aumenta ligeramente el tamaño del texto para mejorar la legibilidad */
  }

 /*our team */
 .team-container-images{
 padding-left: 30px;
 padding-right: 30px;
}

  

  .urgencias-container {
    padding: 15px;
    margin-top: 50px;
  }
  .horario-container, .telefono-urgencias {
    padding: 10px;
  }
  

  .contacto-container {
    width: 90%; /* Ajuste el ancho del contenedor principal para dispositivos móviles */
    max-width: none; /* Elimine el máximo ancho para ocupar el ancho completo en dispositivos pequeños */
    margin-left: 22px;
  }

  .intro h1 {
  
    padding-left: 25px;
  }

  .submit-btn-peluqueria {
    width: 40%;
    margin-left: 30%;
  }

  .overlay-content {
    margin-top: 0px;
  }

  .overlay-content h1 {
    font-size: 14px;
  }

  .custom-btn {
    margin-bottom: -2px;
  }

  .map-container {
    width: 100%;
    height: 442px;
    background-color: rgb(0, 38, 82)
  }

  .map-container iframe {
    width: 120%;
    height: 430px; /* Establece una altura fija o ajusta según tus necesidades */
  
  }
}


@media (min-width: 768px) and (max-width: 1024px) {
  .logo {
    width: 200%;
    margin-left: -320px;
  }
  .hero-container {
    margin-bottom: -40px;
    height: 450px;
  }
  
}
  






